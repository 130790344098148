<template>
    <div v-if="!loading" class="specialTasksGradeContent">
        <span class="h3">
            {{ lesson.lesson.lesson.title }}
        </span>

        <div
            v-if="lesson.lesson.lesson.type === 'additional' && isFinishedEstimating"
            class="profileBox profileBox--2 specialTasksGradeContent__answer"
        >
            <p class="profileBox__title2 colorGreen">
                Поздравляем!
            </p>
            <p class="profileBox__text2">
                Вы успешно оценили ответы других студентов.
            </p>
        </div>

        <div
            v-else-if="lesson.lesson.lesson.type === 'additional' &&
            lesson.additionalLessonStatusByDate === 'openToEstimate' &&
            lesson.homework &&
            lesson.homework.homeworksToEstimate"
            class="profileBox profileBox--2 specialTasksGradeContent__answer"
        >
            <div v-if="!isVisibleAnswer">
                <p class="profileBox__title2">
                    Оценка ответа других студентов
                </p>
                <p class="profileBox__text2">
                    Вам нужно расставить 5 ответов других студентов по местам от 1 до 5,
                    где 1 - ответ, который Вам наимение понравился, а 5 - ответ, который
                    наиболее Вам понравился. У каждого ответа - уникальное место (не может
                    быть 2х ответов с одинаковыми местами). Так же нужно обосновать свой
                    выбор в комментариях.
                </p>
                <div class="specialTasksGradeContent__answerMarksLayout">
                    <div class="specialTasksGradeContent__answerMarks">
                        <p class="specialTasksGradeContent__answerMarks_items">
                            <img
                                alt="fingerDown"
                                src="@/assets/img/common/fingerDown.png"
                            />
                            <span class="text2">Наимение понравился</span>
                        </p>
                        <div class="specialTasksGradeContent__answerMarks_items">
                            1
                            <div class="specialTasksGradeContent__circle specialTasksGradeContent__circle--red"></div>
                        </div>
                        <div class="specialTasksGradeContent__answerMarks_items">
                            2
                            <div class="specialTasksGradeContent__circle specialTasksGradeContent__circle--orange"></div>
                        </div>
                        <div class="specialTasksGradeContent__answerMarks_items">
                            3
                            <div class="specialTasksGradeContent__circle specialTasksGradeContent__circle--yellow"></div>
                        </div>
                        <div class="specialTasksGradeContent__answerMarks_items">
                            4
                            <div class="specialTasksGradeContent__circle specialTasksGradeContent__circle--green"></div>
                        </div>
                        <div class="specialTasksGradeContent__answerMarks_items">
                            5
                            <div class="specialTasksGradeContent__circle specialTasksGradeContent__circle--lightGreen"></div>
                        </div>
                        <p class="specialTasksGradeContent__answerMarks_items">
                            <img alt="fingerUp" src="@/assets/img/common/fingerUp.png" />
                            <span class="text2">Наиболее понравился</span>
                        </p>
                    </div>
                    <Button
                        class="specialTasksGradeContent__answerMarksLayout_btn"
                        title="начать"
                        :onClick="() => onStartEvaluating()"
                    />
                </div>
            </div>

            <div v-else>
                <p class="profileBox__title2">
                    Оценка ответа других студентов
                    <span class="title1 colorGray specialTasksGradeContent__answerItems_tooltipText">
                        Как оценивать?
                        <Tooltip
                            class="specialTasksGradeContent__answerItems_tooltip"
                            extraClass="tooltip--right"
                            :tooltipIcon="require('@/assets/img/common/infoBlue.svg')"
                            text="Вам нужно расставить 5 ответов других студентов по местам от 1 до 5, где 1 -  ответ, который вам наимение понравился, а 5 - ответ, который наиболее Вам понравился. У каждого ответа - уникальное место (не может быть 2х ответов с одинаковыми местами). Так же нужно обосновать свой выбор в комментариях."
                        />
                    </span>
                </p>
                <p class="profileBox__text2">
                    Вы можете прочитать все ответы, прежде чем расставлять их по местам.
                </p>

                <div class="specialTasksGradeContent__cardContent">
                    <div
                        v-if="lesson && lesson.homework && lesson.homework.homeworksToEstimate && lesson.homework.homeworksToEstimate.length > 0"
                        v-for="(he, index) in lesson.homework.homeworksToEstimate"
                        :key="he._id"
                        class="specialTasksGradeContent__card"
                    >
                        <div @click="() => onOpenCard(lettersEng[index])">
                            <p class="specialTasksGradeContent__cardItem">
                        <span class="specialTasksGradeContent__cardItemTitle">
                            Ответ {{lettersEng[index]}}
                            <img
                                v-if="he.estimated"
                                alt="done"
                                src="@/assets/img/pages/study/done.svg"
                            />
                        </span>
                                <img
                                    v-if="selectedCard === lettersEng[index]"
                                    class="specialTasksGradeContent__cardItemArrow"
                                    alt="arrowUp"
                                    src="@/assets/img/common/arrowUp24.svg"
                                />
                                <img
                                    v-else
                                    class="specialTasksGradeContent__cardItemArrow"
                                    alt="arrowDown"
                                    src="@/assets/img/common/arrowDown24.svg"
                                />
                            </p>
                        </div>
                        <div v-if="selectedCard === lettersEng[index]">
                            <p class="specialTasksGradeContent__cardComponent">
                                <span
                                    v-for="reply in he.homework.replies"
                                    :key="reply._id"
                                    v-if="reply.user_answer"
                                >
                                    {{ reply.text }}
                                    <br>
                                </span>
                            </p>
                            <div class="specialTasksGradeContent__cardAnswer">
                                <p class="h4 colorMirage specialTasksGradeContent__cardAnswer_text">
                                    <span v-if="he.estimated">Ваша оценка – {{ he.points }}</span>
                                    <span v-else>Оцените этот ответ</span>
                                </p>
                                <div
                                    class="formgroup specialTasksGradeContent__cardAnswer_text"
                                >

                                    <select
                                        v-if="!he.estimated"
                                        class="select"
                                        @change="(e) => onChangeAnswer(e, lettersEng[index])"
                                        v-model="optionsValue[index]"
                                    >
                                        <option></option>
                                        <option
                                            v-for="answer in answers"
                                            :key="answer.points"
                                            :disabled="answers.filter((filteredAnswer) => {return (Number(filteredAnswer.points) === answer.points && filteredAnswer.selectedBlock !== '')}).length > 0"
                                        >
                                            {{ answer.points }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="!he.estimated">
                                <div class="formgroup specialTasksGradeContent__cardComponent">
                                    <textarea
                                        rows="4"
                                        placeholder="Введите Ваш комментарий"
                                        v-model="textsValue[index]"
                                    ></textarea>
                                </div>

                                <Button
                                    class="specialTasksGradeContent__cardComponent"
                                    extraClass="button--white"
                                    title="оценить"
                                    :isDisabled="isDisabledEvaluationBtn"
                                    :onClick="() => evaluateAnswer(index, he.homework._id.toString())"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="specialTasksGradeContent__cardFooter">
                    <div class="text2 specialTasksGradeContent__answerMarks">
                        <div
                            v-for="answer in answers"
                            :key="answer.points"
                            class="specialTasksGradeContent__answerMarks_cardItems"
                        >
                            {{ answer.points }}
                            <div
                                :class="answer.points === 1 ? `colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--red ${answer.selectedBlock !== '' ? 'specialTasksGradeContent__circle specialTasksGradeContent__circle--redBg' : ''}` :
                                    answer.points === 2 ? `colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--orange ${answer.selectedBlock !== '' ? 'specialTasksGradeContent__circle specialTasksGradeContent__circle--orangeBg' : ''}` :
                                    answer.points === 3 ? `colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--yellow ${answer.selectedBlock !== '' ? 'specialTasksGradeContent__circle specialTasksGradeContent__circle--yellowBg' : ''}` :
                                    answer.points === 4 ? `colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--green ${answer.selectedBlock !== '' ? 'specialTasksGradeContent__circle specialTasksGradeContent__circle--greenBg' : ''}` :
                                    answer.points === 5 ? `colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--lightGreen ${answer.selectedBlock !== '' ? 'specialTasksGradeContent__circle specialTasksGradeContent__circle--lightGreenBg' : ''}` :
                                    'colorWhite specialTasksGradeContent__circle specialTasksGradeContent__circle--red'
                            ">
                                {{ answer.selectedBlock }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="lesson.lesson.lesson.type === 'additional' && lesson.homework" class="profileBox profileBox--2">
            <p class="profileBox__title2 colorRed">
                Внимание!
            </p>
            <p class="profileBox__text2">
                <span v-if="!lesson.estimatedAll">
                    К сожалению, Вы не успели оценить ответы других студентов в отведённый промежуток времени.
                </span>
                <span v-else>
                    К сожалению, Вы не успели оценить ответы других студентов в отведённый промежуток времени, ознакомьтесь с правильным ответом от Александра Герчика.

                    <Button
                        :isLink="true"
                        :link="'/' + $route.params.slug + '/study/' + $route.params.block_priority + '/' + $route.params.lesson_id + '/correct-answer'"
                        class="profileBox__btn2"
                        title="смотреть правильный ответ"
                    />
                </span>
            </p>
        </div>

        <div v-else-if="lesson.lesson.lesson.type === 'additional' && !lesson.homework" class="profileBox profileBox--2">
            <p class="profileBox__title2 colorRed">
                Внимание!
            </p>
            <p class="profileBox__text2">
                <span v-if="!lesson.estimatedAll">
                    К сожалению, Вы не успели дать ответ в отведённый промежуток времени.
                </span>
                <span v-else>
                    К сожалению, Вы не успели дать ответ в отведённый промежуток времени, ознакомьтесь с правильным ответом от Александра Герчика.

                    <Button
                        :isLink="true"
                        :link="'/' + $route.params.slug + '/study/' + $route.params.block_priority + '/' + $route.params.lesson_id + '/correct-answer'"
                        class="profileBox__btn2"
                        title="смотреть правильный ответ"
                    />
                </span>
            </p>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Tooltip from "@/views/components/Tooltip/Tooltip";
import TextArea from "@/views/components/TextArea/TextArea";
export default {
    name: "specialTasksGrade",

    components: {
        Button,
        Tooltip,
        TextArea,
    },

    beforeMount() {
        this.updateLessonStore();


        if (this.lesson &&
            this.lesson.lesson &&
            this.lesson.lesson.lesson &&
            this.lesson.lesson.lesson.type !== "additional" ||
            this.lesson.additionalLessonStatusByDate === "close" ||
            this.lesson.additionalLessonStatusByDate === "closeEarly" ||
            this.lesson.additionalLessonStatusByDate === "unknown") {
            this.$router.push(
                `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}`
            );
        }

        if (this.lesson && this.lesson.homework && this.lesson.homework.homeworksToEstimate && this.lesson.homework.homeworksToEstimate.length) {
            for (let i = 0; i < this.lesson.homework.homeworksToEstimate.length; i++) {
                if (this.lesson.homework.homeworksToEstimate[i].estimated) {
                    const answerIndex = this.answers.findIndex((answer => answer.points === this.lesson.homework.homeworksToEstimate[i].points));
                    this.answers[answerIndex].selectedBlock = this.lettersEng[i];
                    this.answers[answerIndex].estimated = true;
                }
            }
        }

        this.loading = false
    },

    computed: {
        lesson: function() {
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson || {};
        },

        isFinishedEstimating: function() {
            if(this.lesson && this.lesson.homework && this.lesson.homework.homeworksToEstimate && this.lesson.homework.homeworksToEstimate.length === this.answers.filter(item => item.estimated).length) {
                return true;
            }
            else {
                return false;
            }
        },
    },

    data() {
        return {
            loading: true,
            isVisibleAnswer: false,
            isVisibleModal: false,
            isDisabledEvaluationBtn: false,
            selectedCard: "",
            lettersEng: ["A", "B", "C", "D", "E"],
            answers: [{
                points: 1,
                selectedBlock: "",
                estimated: false
            }, {
                points: 2,
                selectedBlock: "",
                estimated: false
            }, {
                points: 3,
                selectedBlock: "",
                estimated: false
            }, {
                points: 4,
                selectedBlock: "",
                estimated: false
            }, {
                points: 5,
                selectedBlock: "",
                estimated: false
            }],
            optionsValue: [],
            textsValue: [],
        };
    },

    methods: {
        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                this.$route.params.slug +
                "/study/" +
                this.$route.params.block_priority +
                "/" +
                this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },

        onChangeAnswer(event, block) {
            if (event.target.value === "") {
                const answerIndex = this.answers.findIndex((answer => answer.selectedBlock === block));
                if (answerIndex !== -1) {
                    this.answers[answerIndex].selectedBlock = "";
                }
            } else {
                const oldAnswerIndex = this.answers.findIndex((answer => answer.selectedBlock === block));
                if (oldAnswerIndex !== -1) {
                    this.answers[oldAnswerIndex].selectedBlock = "";
                }

                const answerIndex = this.answers.findIndex((answer => answer.points === Number(event.target.value)));
                if (answerIndex !== -1) {
                    this.answers[answerIndex].selectedBlock = block;
                }
            }
        },

        onOpenCard(value) {
            if (this.selectedCard === value) {
                this.selectedCard = "";
            } else {
                this.selectedCard = value;
            }
        },

        async evaluateAnswer(index, hwID) {
            if (!this.optionsValue[index]) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Оцените ответ в баллах",
                    },
                });
            } else if (!this.textsValue[index]) {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Введите свой комментарий к ответу",
                    },
                });
            } else {
                this.isDisabledEvaluationBtn = true;

                const data = {
                    text          : this.textsValue[index],
                    points        : this.optionsValue[index],
                    estimatedHW_id: hwID
                };


                const estimateAnswerResponse = await this.axios.post(
                    "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id +
                    "/answer/additional/estimate", data
                );

                if (estimateAnswerResponse && estimateAnswerResponse.data && estimateAnswerResponse.data.result) {
                    await this.updateLessonStore();

                    const answerIndex = this.answers.findIndex((answer => answer.points === Number(this.optionsValue[index])));
                    if (answerIndex !== -1) {
                        this.answers[answerIndex].estimated = true;
                    }

                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text: "Ответ успешно принят!",
                        },
                    });
                }

                this.isDisabledEvaluationBtn = false;
            }
        },

        onStartEvaluating() {
            this.isVisibleAnswer = true;
        },
    },
};
</script>

<style lang="scss">
@import "./specialTasksGrade.scss";
</style>
